import { FormEvent, useState } from 'react';
import styles from './styles.module.css';
import axios from 'axios';

const Form = () => {

    async function postDataToApi(formData: any) {
        try {
          const response = await axios.post(' https://tracking.getarchus.com/platform/demoRequest', formData);
          console.log('Response data:', response.data);
        } catch (error) {
          console.error('Error posting data:', error);
        }
      }


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobileNo: '',
        companyName: '',
    });

    const [disableInput, setDisableInput] = useState(false);
    const [buttonText, setButtonText] = useState('Submit');

    function handleFormSubmit(event: FormEvent) {
        event.preventDefault();
        postDataToApi(formData)
    }

    function handleInputChange(event: any) {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    }    

    function handleButtonClick(event: any) {
        setButtonText('Thanks, your request has been received');
        setDisableInput(true);
        setFormData({
            name: '',
            email: '',
            mobileNo: '',
            companyName: '',
        });
    }

    return (
        <form className={styles.form_container} onSubmit = {handleFormSubmit}>
            <div className={styles.form_element}>
                <label className={styles.label}>Name</label>
                <input name = 'name' className={styles.input} placeholder='Your Name' onChange = {handleInputChange}  disabled = {disableInput}></input>
            </div>
            <div className={styles.same_line}>
                <div className={styles.form_element}>
                    <label className={styles.label}>Email</label>
                    <input name = 'email' className={styles.input} placeholder='Work Email' onChange = {handleInputChange} disabled = {disableInput}></input>
                </div>
                <div className={styles.form_element}>
                    <label className={styles.label}>Mobile Number</label>
                    <input name = 'mobileNo' className={styles.input} placeholder='Phone' onChange = {handleInputChange} disabled = {disableInput}></input>
                </div>
            </div>
            <div className={styles.form_element}>
                <label className={styles.label}>Company Name</label>
                <input name = 'companyName' className={styles.input} placeholder='Your Company Name' onChange = {handleInputChange} disabled = {disableInput}></input>
            </div>
            <button onClick={handleButtonClick} className={disableInput ?  styles.submitted : styles.buttonBeforeSubmission}>
                {buttonText}
            </button>
        </form>
    )
}

export default Form;