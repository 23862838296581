import styles from './styles.module.css';
import {ReactComponent as Logo} from '../../images/Union.svg';
import React, { useEffect, useState } from 'react';

interface BannerProps {
  current: any;
}

const Banner: React.FC<BannerProps> = ({ current }) => {
  function handleButtonClick() {
    current?.current?.scrollIntoView({ behavior: 'smooth' });
  }

    return (
        <div className={styles.banner}>
            <div className={styles.navbar}>
                <div className={styles.logo}>
                    <Logo className={styles.logo_image}></Logo>
                    <div className={styles.logo_text}>Archus</div>
                </div>
                <div className={styles.buttons}>
                    <button className={styles.contact_button} onClick = {handleButtonClick}>Contact Us</button>
                    <button className={styles.demo_button} onClick = {handleButtonClick}>Request Demo</button>
                </div>
            </div>

            <div className={styles.banner_description}>
                <div className={styles.text_one}>AI Powered Freight Forwarding Software</div>
                <div className={styles.text_two}>Modernize Your Freight Forwarding Business</div>
                <div className={styles.text_three}>Master your sales pipeline and streamline your operations to boost efficiency and reduce costs</div>
                <div className={styles.demo_button_container}><button className={styles.demo_button_second} onClick = {handleButtonClick}>Request Demo</button></div>
            </div>
        </div>
    )
}

export default Banner;