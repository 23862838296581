import { useEffect, useLayoutEffect, useState } from 'react';
import styles from './styles.module.css';

interface Props {
    image: string;
    heading: string;
    sub_heading: string;
    image_type: string;
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

const Frame = ({image, heading, sub_heading, image_type}:Props) => {

const [width, height] = useWindowSize();
const getBackgroundSize = () => {

    if (window.innerWidth <= 480) {
      return '380px 217px';
    }
    if (window.innerWidth <= 600) {
        return '300px 180px';
    }
    if (window.innerWidth <= 960) {
        return '380px 217px';
    }
    if (image_type === 'man'){
        return '540px 380px';
    }
    if (image_type === 'laptop'){
        return '625px 380px';
    }

  };

const getRightPosition = () =>{
    if (window.innerWidth <= 480) {
        if (image_type === 'laptop')
            return '-20px';
        else
            return '55px';
    }
    if (window.innerWidth <= 600) {
        if (image_type === 'laptop') {
            return '-280px';
        } else {
            return '-200px';
        }

    }
    if (window.innerWidth <= 960) {
        return '-140px';
    }
    else
    {
        if (image_type === 'laptop'){
            return '50px';
        }
        else{
            return '70px';
        }
    }
}

    return (
        <div className={styles.frame_container}>
            <div className={styles.text_container}>
                <div className={styles.heading}>
                    {heading}
                </div>
                <div className={styles.sub_heading}>
                    {sub_heading}
                </div>
            </div>
            <div className={styles.contain_image}>
            <div className={styles.image_container} style = {{ background: `url(${image})`, backgroundRepeat: 'no-repeat', backgroundSize: getBackgroundSize(), right: getRightPosition()}}>
            </div>
            </div>
        </div>
    )
}

export default Frame;