import styles from './styles.module.css';

interface Props {
    heading: string;
    sub_heading: string;
}

const SecondFrame = ({ heading, sub_heading}:Props) => {
    return (
        <div className={styles.frame_container}>
            <div className={styles.box_container}>
            <div className={styles.first_box}>
                    My customer has a unique requirement for this booking. How do I make sure it’s followed up by the operations team?
                </div>
                <div className={styles.second_box}>
                    Not to worry! I will create action items for your operations team at the end of this booking.
                </div>
            </div>
            <div className={styles.text_container}>
                <div className={styles.heading}>
                    {heading}
                </div>
                <div className={styles.sub_heading}>
                    {sub_heading}
                </div>
            </div>
        </div>
    )
}

export default SecondFrame;