import styles from './styles.module.css';
import Form from './Form';

import React from 'react';

interface ContactFormProps {
    cur: any;
}

const ContactForm: React.FC<ContactFormProps> = ({ cur }) => {
    return (
        <div ref={cur} className={styles.contact_container}>
            <div className={styles.text_container}>
                <div className={styles.heading}>
                    Request a Demo
                </div>
                <div className={styles.sub_heading}>
                    Experience the Future of Freight Forwarding with Archus
                </div>
            </div>
            <div className={styles.image_container}>
                <Form></Form>
            </div>
        </div>
    )
}

export default ContactForm;