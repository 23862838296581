import styles from './styles.module.css';
import {ReactComponent as Logo} from '../../images/Union.svg';
import {ReactComponent as Twitter} from '../../images/tabler_brand-x.svg';
import {ReactComponent as Youtube} from '../../images/logos_youtube-icon.svg';
import {ReactComponent as Facebook} from '../../images/devicon_facebook.svg';
import {ReactComponent as LinkedIn} from '../../images/ri_linkedin-fill.svg';

const Footer = () => {
    return (
        <div className={styles.footer_container}>
            <div className={styles.line}>
                <div className={styles.first_row}>
                    <div className={styles.logo}>
                        <Logo></Logo>
                        <div className={styles.logo_text}>Archus</div>
                    </div>
                    <div className={styles.icons}>
                        <Youtube></Youtube>
                        <Twitter></Twitter>
                        <Facebook></Facebook>
                        <LinkedIn></LinkedIn>
                    </div>
                </div>
                <div className={styles.second_row}>
                    <span className={styles.contact}>shashwat@getarchus.com | +91 98109 72373</span>
                    <span className={styles.links}>
                        <span className={styles.link_element}>Terms and Conditions</span>
                        <span className={styles.link_element}>Privacy Policy</span>
                        <span className={styles.copyright}>@2023 Archus</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer;