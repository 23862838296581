import Banner from './Banner';
import styles from './styles.module.css';
import Frame from './Frame';
import SecondFrame from './SecondFrame';
import Footer from './Footer';
import ContactForm  from './ContactForm';
import { useRef } from 'react';

const IMAGE_1 = './storefront_image.png'
const HEADING_1 = 'Elevate Your Brand with Online Storefronts';
const SUB_HEADING_1 = 'Create an impressive online presence effortlessly with our tailor-made storefronts. Captivate your audience, win more clients!';

const HEADING_2 = 'Save up to 70% on demurrage, detention and amendment penalties with our timely notifications and reminders';
const SUB_HEADING_2 = 'Our smart workflows identify dependencies, optimizing processes and boosting customer satisfaction.';

const IMAGE_2 = './tablet_man.png'
const HEADING_3 = 'Access it Anywhere, Anytime with Cloud Convenience ';
const SUB_HEADING_3 = 'Carry your freight forwarding office with you everywhere you go. Enjoy the results without the hassle of software downloads.';


const LandingPage = () => {
    const ref = useRef(null);
    return (
       <div className={styles.container}>
            <Banner current = {ref} ></Banner>
            <div className={styles.image_container}>
                <div className={styles.stats_image}></div>
            </div>
            <div className={styles.text_container}>
                <div className={styles.normal_text}>Excel at Freight Forwarding</div>
            </div>
            <Frame image={IMAGE_1} heading={HEADING_1} sub_heading={SUB_HEADING_1} image_type = 'laptop' ></Frame>

            <SecondFrame heading={HEADING_2} sub_heading={SUB_HEADING_2}></SecondFrame>

            <Frame image={IMAGE_2} heading={HEADING_3} sub_heading={SUB_HEADING_3} image_type='man' ></Frame>

            <ContactForm cur={ref}></ContactForm>

            <Footer></Footer>
       </div>
    )
}

export default LandingPage;